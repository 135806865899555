<template lang="pug">
  .slider-section.slider-section--left.rp(v-view)
    .arm(style="margin-top: 2rem")
      .content
        .items
          //- .project.nftfreebie(style="overflow: visible")
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/8741",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Freebie
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") free&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 1000
            //- a(href="https://www.hicetnunc.xyz/objkt/8741", target="_blank")
            //-   img(
            //-     src="https://cdn.dribbble.com/users/6661484/screenshots/15325940/media/b179692462422bb939d9ea720cbf4321.gif"
            //-   )
  
          //- .project.nftwist(style="overflow: visible")
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7382",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            //- a(
            //-   href="https://codepen.io/ericrosedev/full/qBqvjaO",
            //-   target="_blank"
            //- )
            //-   img(
            //-     src="https://cdn.dribbble.com/users/6661484/screenshots/15292285/media/09e50d10fc277ba26b5f4b1ab8031af9.gif"
            //-   )
  
          .project.grurple
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7381",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/MWbGjRb",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/tg.gif')" 
              )
  
          .project.sio
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7384",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/XWNaNwg",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/sio.gif')" 
              )
  
          .project.ror
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7371",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/WNorpRd",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/ror.gif')" 
              )
  
          .project.cyj
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7369",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/eYdoXbV",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/cyj.gif')" 
              )
  
          .project.magm
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7367",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/abmMVxx",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/mm.gif')" 
              )
  
          .project.revrec
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7366",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/abmryOR",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/rr.gif')" 
              )
  
          .project.dilis
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7365",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/ZEpqjOZ",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/dilis.gif')" 
              )
  
          .project.pgmc
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7360",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/vYXpwwo",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/pgmc.gif')" 
              )
  
          .project.ajttbc
            //- .nft
            //-   a.nftAnchor(
            //-     href="https://www.hicetnunc.xyz/objkt/7394",
            //-     target="_blank"
            //-   )
            //-     .title
            //-       span Collect
            //-     .tagline
            //-       span A&nbsp;
            //-       span(style="font-weight: 100") clean&nbsp;
            //-       span NFT!
            //-     .subline
            //-       span Limit 100
            a(
              href="https://codepen.io/ericrosedev/full/KKgyGMa",
              target="_blank"
            )
              img(
                :src="require('@/assets/rp/ajttbc.png')" 
              )
  
          .project.spisc
            a(
              href="https://codepen.io/ericrosedev/full/yLaxXGe",
              target="_blank"
            )
              img(src="https://assets.codepen.io/5422341/snowman.png")
  
    .armbg
  </template>
  
  <script>
  export default {
    name: "RecentProjects",
  };
  </script>
  
  <style lang="scss">
  @import "../scss/_scss.scss";
  
  .rp {
    .items {
      padding-top: 5rem;
      margin-top: -5rem;
    }
  
    .project {
      overflow: visible;
      position: relative;
      width: 400px;
      height: 300px;
  
      .nft {
        opacity: 0;
        z-index: 1;
        position: absolute;
        top: -1rem;
        right: 1rem;
        width: 7.375rem;
        height: 7.375rem;
        border-radius: 50%;
        background-color: #91E7A3;
        background: linear-gradient(to top, #91E7A3, #feffc5);
        transition: all 0.25s;
  
        a.nftAnchor {
          text-decoration: none;
        }
  
        .title {
          padding-top: 0.35rem;
          transition: all 0.25s;
          span {
            font-size: 1.438rem;
            font-weight: bold;
            color: #FF7575;
          }
        }
  
        .tagline {
          span {
            font-size: 1.1rem;
            font-weight: 500;
            color: #828282;
          }
        }
  
        .subline {
          span {
            font-size: 0.95rem;
            color: #afafaf;
          }
        }
      }
  
      > a {
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        margin-top: 1rem;
        width: 375px;
        height: 300px;
  
        img {
          width: 350px;
          border-radius: 1rem;
        }
  
        &::after {
          transition: all 0.5s;
          bottom: -2.25rem;
          font-size: 32px;
          white-space: pre;
        }
  
        &:hover::after {
          transform: rotate(-3deg);
        }
      }
  
      &.nftfreebie {
        > a::after {
          content: "🙌 Meta gift 🙌";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.nftwist {
        > a::after {
          content: "↪️ The NFTwist";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.grurple {
        > a::after {
          content: "🟢Grurple power🟣";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.sio {
        > a::after {
          content: "📄 Smooth it over";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.ror {
        > a::after {
          content: "🎈 Rise or raze";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.cyj {
        > a::after {
          content: "✈️ Cool your jets";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.magm {
        > a::after {
          content: "✨ Magic modal";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.revrec {
        > a::after {
          content: "🤖 Reverse recaptcha";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.dilis {
        > a::after {
          content: "💦 Drop it like its swamp";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.pgmc {
        > a::after {
          content: "🎁 Precision-guided \A merry christmas";
          position: absolute;
          left: 0;
          font-size: 30px;
          color: #8ac897;
          width: 100%;
          line-height: 34px;
          bottom: -4.8rem;
        }
      }
  
      &.ajttbc {
        > a::after {
          content: "🧝🏻 A journey to the big city";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
  
      &.spisc {
        > a::after {
          content: "☃️ Spring is coming";
          position: absolute;
          left: 0;
          color: #8ac897;
          width: 100%;
        }
      }
    }
  
    .project:hover {
      .nft {
        opacity: 1;
        top: -4.6rem;
        transform: rotate(10deg);
  
        .title {
          padding-top: 0.7rem;
        }
      }
    }
  }
  
  .rp::after {
    content: "Recent Stuff";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 48px;
    font-size: 78px;
    transition: all 0.5s;
    color: #d2d2d2;
  
    @media (max-width: 1120px) {
      font-size: 48px;
      top: 3rem;
    }
  
    @media (max-width: 400px) {
      left: 1rem;
      text-align: left;
    }
  }
  .rp.view-in {
    .arm {
      transform: translateX(0);
    }
    &::after {
      transform: translateY(-8.5rem) rotate(-3deg);
      opacity: 1;
    }
  }
  .rp.view-in--gt-third {
    .arm {
      transform: translateX(0);
    }
  }
  .rp.view-in--gt-half {
    .arm {
      transform: translateX(0);
    }
  }
  .rp.view-in--full {
    .arm {
      transform: translateX(0);
    }
  }
  .rp.view-out {
    .arm {
      transform: translateX(-80vw);
    }
  }
  </style>